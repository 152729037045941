<template>
  <div class="pl-1 pt-1">
    <b-nav card-header pills class="m-0">
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 8 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 8"
        @click="changeNav(8)"
      >
        Current
        <span v-if="G_CLIENTS_COUNTER_CURRENT > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_CURRENT"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 9 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 9"
        @click="changeNav(9)"
      >
        One Month Late
        <span v-if="G_CLIENTS_COUNTER_ONE_MONTH_LATE > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_ONE_MONTH_LATE"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
      <b-nav-item
        exact-active-class="active router-link-active"
        :link-classes="[
          'sub-tab-nav',
          'px-3',
          statusNav === 10 ? 'router-link-active' : '',
          bgTabsNavs,
        ]"
        :active="statusNav === 10"
        @click="changeNav(10)"
      >
        Two Month Late
        <span v-if="G_CLIENTS_COUNTER_TWO_MONTH_LATE > 0" class="ml-2"
          ><feather-icon
            icon
            :badge="G_CLIENTS_COUNTER_TWO_MONTH_LATE"
            badge-classes="badge-danger"
        /></span>
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-info border-3 border-table-radius px-0 w-100"
    >
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refreshTable()"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refClientsListActive"
            small
            no-provider-filtering
            :fields="fields"
            responsive="sm"
            show-empty
            sticky-header="50vh"
            :busy.sync="isBusy"
            :items="detailPaymentsProvider"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #head(balance)="data">
              <div class="text-left">
                {{ data.label }}
              </div>
            </template>
            <template #head(sms)="data">
              <div class="ml-1">
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell(client_name)="data">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'financial-clients-account',
                  params: {
                    idClient: data.item.client_account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}
              </router-link>
              <span v-if="data.item.user_responsible" class="text-warning">
                <tabler-icon
                  :icon="
                    data.item.user_responsible === currentUser.user_id
                      ? 'StarIcon'
                      : 'LockIcon'
                  "
                  size="15"
                />
                <strong>CEO's client</strong>
              </span>
              <div>
                <status-account :account="data.item" />
              </div>
              <span> {{ data.item.account }}</span>
              <br />
              <span>
                {{
                  data.item.mobile ? data.item.mobile : "(###) ###-####"
                }}</span
              >
              <br />
              <b-badge variant="primary">
                {{ data.item.client_type }}
              </b-badge>
              <p class="m-0">
                <b-badge
                  v-if="data.item.pay_state == 'mayor'"
                  pill
                  style="padding: 6px 8px"
                  variant="danger"
                >
                  OVER FLOW
                </b-badge>
              </p>
              <p class="m-0">
                <b-badge
                  v-if="data.item.reschedule == 'active'"
                  pill
                  style="padding: 6px 8px"
                  variant="info"
                >
                  <feather-icon icon="CalendarIcon" />
                  SCHEDULED CHARGE
                </b-badge>
              </p>
            </template>

            <template #cell(payment_type)="data">
              <div class="d-flex pt-1">
                <img
                  v-if="
                    data.item.type_payment == 'PA' &&
                    data.item.status_payment == '1' &&
                    data.item.srb == 'ACTIVE'
                  "
                  :title="'Automatic/' + data.item.srb"
                  :src="'/assets/images/social-network/paymentType/paymenttype-auto.ico'"
                  alt=""
                />
                <img
                  v-if="
                    data.item.type_payment == 'PA' &&
                    data.item.status_payment == '0' &&
                    data.item.srb != 'ACTIVE'
                  "
                  :title="'Automatic/' + data.item.srb"
                  :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
                  alt=""
                />
                <img
                  v-if="
                    data.item.type_payment == 'PA' &&
                    data.item.status_payment == '1' &&
                    data.item.srb != 'ACTIVE'
                  "
                  :title="'Automatic/' + data.item.srb"
                  :src="'/assets/images/social-network/paymentType/paymenttype-auto-no.ico'"
                  alt=""
                />
                <img
                  v-if="data.item.type_payment == 'MA'"
                  title="Manual"
                  :src="'/assets/images/social-network/paymentType/paymenttype-manual.ico'"
                  alt=""
                />
                <img
                  v-if="data.item.type_payment == 'OT'"
                  title="Others"
                  :src="'/assets/images/social-network/paymentType/paymenttype-others.ico'"
                  alt=""
                />
                <span v-if="data.item.day_payment != null">
                  ({{ data.item.day_payment }})
                </span>
                <feather-icon
                  icon="Edit2Icon"
                  class="cursor-pointer ml-1"
                  size="15"
                  @click="openModalMonthlyPayment(data.item)"
                />
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="cursor-pointer ml-1"
                  size="15"
                  @click="openModalHistoryPayment(data.item)"
                />
              </div>

              <!-- openModalHistoryPayment -->
            </template>

            <template #cell(paid)="data">
              <div class="d-flex pt-1">
                <span
                  :style="
                    data.item.ispayment === 0
                      ? 'color:#FF0000'
                      : data.item.ispayment === 1
                      ? 'color:#00CC00'
                      : 'color:orange'
                  "
                >
                  {{
                    data.item.ispayment === 0
                      ? "NO"
                      : data.item.ispayment === 1
                      ? "YES"
                      : "PENDING"
                  }}
                </span>
              </div>
            </template>
            <template #cell(balance)="data">
              <div class="text-center d-flex pt-1">
                <b-button
                  variant="outline"
                  class="m-0 p-0 pt-0"
                  @click="openBalance(data.item)"
                >
                  <span
                    style="color: #f74f4f; text-align: center"
                    class="cursor-pointer"
                    :class="[
                      data.item.balance < 0 ? 'text-danger' : 'text-success',
                    ]"
                    >$ {{ data.item.balance | currencyZero }}</span
                  >
                </b-button>
              </div>
            </template>
            <template #cell(amount)="data">
              <div class="d-flex pt-1 gap-1">
                <div
                  v-if="data.item.ispayment == 0 || data.item.ispayment == 2"
                >
                  <span class="text-danger" style="white-space: nowrap">
                    {{
                      data.item.monthly_amount
                        ? " $ -" + data.item.monthly_amount
                        : ""
                    }}
                    <span v-if="!!data.item.type_payment_ce" class="text-muted">
                      ({{ data.item.type_payment_ce === 1 ? "SP" : "MP" }})
                    </span>
                  </span>
                </div>
                <div v-else>
                  <ul style="padding-left: 0px; margin-bottom: 0px">
                    <li
                      v-for="(payment, index) in JSON.parse(data.item.payments)"
                      :key="index"
                      style="list-style: none"
                    >
                      <money
                        v-model="payment.amount"
                        v-b-tooltip.hover
                        :title="payment.settlement_date | myGlobal"
                        class="m-0 p-0 text-success"
                        v-bind="maskMoney"
                        style="
                          border: 0px solid transparent;
                          cursor: pointer;
                          width: 55px;
                        "
                        disabled
                      />
                    </li>
                  </ul>
                </div>
                <b-button
                  variant="outline"
                  class="m-0 pt-0"
                  @click="processPayment(data.item)"
                >
                  <feather-icon
                    v-b-tooltip.hover.right="'Process Payment'"
                    icon="CheckCircleIcon"
                    size="20"
                    class="text-success"
                  />
                </b-button>
              </div>
            </template>
            <template #cell(enroll_date)="data">
              <div class="d-flex pt-1 text-center">
                <span>
                  {{ data.item.enroll_date | myGlobal }}
                </span>
              </div>
            </template>
            <template #cell(last_payment)="data">
              <div class="d-flex pt-1 text-center">
                <span>
                  {{ data.item.last_payment | myGlobal }}
                </span>
              </div>
              <div class="mp-pending">
                <feather-icon
                  v-if="data.item.pending_payments == 1"
                  v-b-tooltip.hover
                  icon="CreditCardIcon"
                  class="ml-1"
                  style="margin-top: 5px"
                  size="18"
                  title="1 Monthly Payment Pending"
                />
              </div>
            </template>
            <!-- <template #cell(count_call)="data">
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: space-between;
                  margin-top: 5px;
                "
              >
                <div style="margin-bottom: 5px">
                  <b-badge
                    class="cursor-pointer"
                    variant="primary"
                    @click="openCallsAndSmsTrackingModal(data.item, 1)"
                    >{{
                      data.item.count_call === null ? 0 : data.item.count_call
                    }}</b-badge
                  >
                  Calls
                </div>
                <div style="margin-bottom: 5px">
                  <b-badge
                    class="cursor-pointer"
                    variant="primary"
                    @click="openCallsAndSmsTrackingModal(data.item, 2)"
                    >{{
                      data.item.count_sms === null ? 0 : data.item.count_sms
                    }}</b-badge
                  >
                  SMS
                </div>
                <div style="margin-bottom: 5px">
                  <b-badge
                    class="cursor-pointer"
                    variant="primary"
                    @click="openCallsAndSmsTrackingModal(data.item, 4)"
                    >{{
                      data.item.count_not === null ? 0 : data.item.count_not
                    }}</b-badge
                  >
                  Noti
                </div>
              </div>
            </template> -->
            <!-- <template #cell(count_sms)="data">
              <div class="d-flex pt-1">
                <b-badge
                  class="cursor-pointer"
                  variant="primary"
                  @click="openCallsAndSmsTrackingModal(data.item, 2)"
                  >{{
                    data.item.count_sms === null ? 0 : data.item.count_sms
                  }}</b-badge
                >
              </div>
            </template>

            <template #cell(count_not)="data">
              <div class="d-flex pt-1">
                <b-badge
                  class="cursor-pointer"
                  variant="primary"
                  @click="openCallsAndSmsTrackingModal(data.item, 4)"
                  >{{
                    data.item.count_not === null ? 0 : data.item.count_not
                  }}</b-badge
                >
              </div>
            </template>

            <template #cell(tracking)="data">
              <div class="d-flex pt-1">
                <b-badge
                  class="cursor-pointer"
                  variant="primary"
                  @click="openCallsAndSmsTrackingModal(data.item, 3)"
                  >{{
                    data.item.count_commentaries === null
                      ? 0
                      : data.item.count_commentaries
                  }}</b-badge
                >
              </div>
            </template> -->

            <template #cell(contact_schedule)="data">
              <div class="pt-1">
                <show-more-text :text="data.item.contact_schedule" />
              </div>
            </template>
            <template #cell(fromm)="data">
              {{ data.item.fromm | myGlobal }}
            </template>

            <template #cell(client_result)="data">
              <!-- agrandar columna -->
              <div class="d-flex pt-1">
                <result-select
                  :key="data.item.id"
                  :client_account_id="data.item.client_account_id"
                  :status="data.item.client_result_status"
                  :transaction_id="data.item.transaction_id"
                  :result_id="data.item.client_result_id"
                  :results="results"
                  style="margin-right: 5px"
                  @hasSameOptionSelected="
                    (e) => hasSameOptionSelected(e, data.item)
                  "
                  @setResult="(e) => setResult(e, data.item)"
                />
                <FeatherIcon
                  icon="ListIcon"
                  class="text-primary cursor-pointer"
                  size="20"
                  @click="openResultTracking(data.item.transaction_id)"
                />
              </div>
            </template>
            <!-- <template #cell(client_label)="{ item }">
              <div class="d-flex pt-1">
                <client-label-select
                  :status="item.client_label_status"
                  :label_id="item.client_label_id"
                  :transaction_id="item.transaction_id"
                  :client_account_id="item.client_account_id"
                  :other_label="item.other_label"
                  :labels="labels"
                  :item="item"
                  @refreshTable="$refs.refClientsListActive.refresh()"
                  @onSaveSelection="onSaveSelection"
                  @onOtherLabel="(e, a) => onOtherLabel(e, a, item)"
                />
                <FeatherIcon
                  icon="ListIcon"
                  class="text-primary cursor-pointer"
                  size="20"
                  @click="openLabelTracking(item.client_account_id)"
                />
              </div>
            </template> -->

            <template #cell(client_state)="data">
              <div class="pt-1">
                {{ data.item.client_state }}
              </div>
            </template>

            <template #cell(sms)="data">
              <div class="d-flex align-items-center">
                <div
                  v-if="
                    data.item.user_responsible == currentUser.user_id ||
                    data.item.user_responsible == null
                  "
                  class="mr-1"
                >
                  <feather-icon
                    size="20"
                    icon="MessageSquareIcon"
                    class="text-primary cursor-pointer"
                    @click="openAddSmsModal(data.item, 2)"
                  />
                </div>
                <div>
                  <feather-icon
                    size="20"
                    icon="ListIcon"
                    class="text-primary cursor-pointer"
                    @click="openSmsHistoryModal(data.item.client_account_id)"
                  />
                </div>
              </div>
            </template>

            <template #cell(actions)="data">
              <b-dropdown
                v-if="
                  data.item.user_responsible == currentUser.user_id ||
                  data.item.user_responsible == null
                "
                variant="link"
                no-caret
                boundary="viewport"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="openAddSmsModal(data.item, 2)">
                  <feather-icon icon="MessageCircleIcon" class="mr-1" />
                  <span>Send SMS</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #custom-foot>
              <b-tr class="bg-dark">
                <b-th />
                <b-th />
                <b-th />

                <b-th>
                  <div style="margin: 0.5rem" />
                  <span class="font-small-3 text-center">Paid</span>
                  <br />
                  <br />
                  <div />
                  <span class="text-center">Not Paid </span>
                  <div />
                </b-th>
                <b-th class="footer-dark">
                  <b-badge
                    class="text-capitalize w-75 font-small-3"
                    style="padding: 0.5rem"
                    variant="success"
                  >
                    $ {{ totalAmount | currency }}
                  </b-badge>

                  <br />

                  <b-badge
                    class="text-capitalize w-75 font-small-3"
                    style="padding: 0.5rem"
                    variant="danger"
                  >
                    $ {{ totalPending | currency }}
                  </b-badge>
                </b-th>
                <b-th />
                <b-th />
                <b-th />
                <b-th />
                <b-th />
              </b-tr>
            </template>
          </b-table>
        </template>
      </filter-slot>
      <modal-monthly-edit
        v-if="modalMonthlyPayment"
        :client-account="clientAccount"
        :modal-monthly-payment="modalMonthlyPayment"
        @close="closeModalMonthlyPayment"
      />
      <modal-approve-supervisor
        v-if="modalApproveSupervisorOn"
        :modal-approve-supervisor="modalApproveSupervisorOn"
        @closeModalApprove="modalApproveSupervisorOn = false"
      />
      <!-- @approveSup="chargesdisabled({ id: itemId })" -->
      <modal-process-payment
        v-if="stateProcessPayment"
        :payment-information="paymentInformation"
        :type-tab="paymentInformation.type_month"
        @refreshTable="$refs['refClientsListActive'].refresh()"
        @close="stateProcessPayment = false"
        @hideModal="stateProcessPayment = false"
        @onManualPay="closePayModal"
        @onOtherPay="closePayModal"
      />
      <modal-balance
        v-if="stateBalance"
        :payment-information="paymentInformation"
        @hideModal="stateBalance = false"
      />
      <add-sms-modal-component
        v-if="addSmsModal"
        :client-data="clientData"
        :action-type="actionType"
        @closeModal="addSmsModal = false"
        @onAddSms="onAddSms"
      />

      <!-- <add-calls-and-sms-modal
        v-if="addCallsAndSmsModal"
        :client-data="clientData"
        :action-type="actionType"
        @refresh="$refs['refClientsListActive'].refresh()"
        @closeModal="closeModal"
        @onAddCommentary="onAddCommentary"
        @onSmsCallNotificationAdd="onSmsCallNotificationAdd"
      /> -->
      <notification-app-modal
        v-if="notificationAppModalOn"
        :client-selected="clientSelected"
        @close="notificationAppModalOn = false"
        @refresh="$refs['refClientsListActive'].refresh()"
        @onAddNotification="onAddNotification"
      />

      <calls-and-sms-tracking
        v-if="callsAndSmsTrackingModal"
        :client-data="clientData"
        :type-tracking="typeTracking"
        @closeModal="closeModal"
      />

      <reschedule-pending-payment-modal
        v-if="reschedulePendingPaymentModal"
        :client-data="clientData"
        @refresh="$refs['refClientsListActive'].refresh()"
        @closeModal="closeModal"
      />
      <modal-send-statement
        v-if="modalSendStatement"
        :modal-status-report="modalSendStatement"
        :client-param="clientInformationT"
        @closeModalStatus="modalSendStatement = false"
      />
      <modal-send-email
        v-if="modalSendEmail"
        :modal-send-email="modalSendEmail"
        :name-leads="name_clients_arr"
        :typesms="0"
        @closeModal="closeSendEmail"
      />
      <modal-history-monthly-payment
        v-if="modalHistoryPayment"
        :client-prop="selectedClientTwo"
        @close="closeModalHistoryPayment"
      />
      <!-- derivation action -->
      <derivation-action-modal
        v-if="derivationActionModal"
        :derivation-data="derivationData"
        @send="updateRow"
        @closeModal="derivationActionModal = false"
      />
      <!--    derivation tracking modal-->
      <derivation-tracking-modal
        v-if="derivationTrackingModal"
        :derivation-data="derivationData"
        @closeModal="closeDerivationTrackingModal"
      />
      <modal-result-tracking
        v-if="modalResultActive"
        :transaction-id="transaction_id"
        @close="closeResultModal"
      />
      <modal-label-tracking
        v-if="modalLabelActive"
        :account-id="client_account_id"
        @close="closeLabelModal"
      />
      <payment-schedule
        v-if="showPaymentSchedule"
        :payment-information="paymentInformation"
        @hidden="showPaymentSchedule = false"
        @refreshTable="$refs['refClientsListActive'].refresh()"
        @onManualPay="closePayModal"
        @onOtherPay="closePayModal"
      />

      <sms-client-history
        v-if="showSmsHistoryModal"
        :client-account-id="currentClientAccountId"
        @close="closeSmsHistoryModal"
      />

      <b-modal
        v-if="showModalDashboardPayments"
        v-model="showModalDashboardPayments"
        size="xl"
        hide-footer
        :title="'Dashboard Payment: ' + paymentInformation.client_name"
        @hidden="showModalDashboardPayments = false"
        title-class="bolder-title"
      >
        <dashboard-payments
          v-if="showModalDashboardPayments"
          :client-account-id="paymentInformation.client_account_id"
          :read-only="true"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
// Table clients
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";

import FiltersClientsData from "@/views/ce-digital/sub-modules/financial/view/clients/components/clients-active.filters";

import { mapGetters, mapActions } from "vuex";
import DashboardFinancial from "@/views/ce-digital/sub-modules/financial/view/dashboards/dashboard-financial.";
import vSelect from "vue-select";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ModalProcessPayment from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalProcessPayment.vue";
import ModalBalance from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalBalance.vue";
import ModalClientDetail from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/ModalClientDetail.vue";
import AddSmsModalComponent from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddSmsModal";
import AddCallsAndSmsModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/AddCallsAndSmsModal";
import CallsAndSmsTracking from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/CallsAndSmsTrackingModal";
import NotificationAppModal from "@/views/ce-digital/sub-modules/financial/view/pending-payments/view/components/modal/NotificationAppModal";
import ReschedulePendingPaymentModal from "@/views/ce-digital/sub-modules/financial/view/calls-and-sms/components/modals/ReschedulePendingPaymentModal";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import moment from "moment";
import ModalSendStatement from "@/views/commons/components/clients/dashboard/information-client/modals/ModalStatusReport";
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import ModalHistoryMonthlyPayment from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryMonthlyPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
import ResultSelect from "@/views/commons/components/reports/tabs/debtor-clients/components/ResultSelect.vue";
import ClientLabelSelect from "@/views/commons/components/reports/tabs/debtor-clients/components/ClientLabelSelect.vue";
import ModalResultTracking from "@/views/commons/components/reports/tabs/debtor-clients/components/ModalResultTracking.vue";
import ModalLabelTracking from "@/views/commons/components/reports/tabs/debtor-clients/components/ModalLabelTracking.vue";
import Fields from "@/views/ce-digital/sub-modules/financial/view/clients/data/fieds.active.data.js";
import ShowMoreText from "@/views/ce-digital/sub-modules/financial/view/clients/components/ShowMoreText.vue";
import PaymentSchedule from "@/views/ce-digital/sub-modules/financial/view/clients/components/modales/PaymentScheduleModal.vue";
import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";
import SmsClientHistory from "@/views/ce-digital/sub-modules/financial/view/clients/components/modales/SmsClientHistory.vue";

export default {
  components: {
    FilterSlot,
    vSelect,
    ModalMonthlyEdit,
    ModalApproveSupervisor,
    ModalProcessPayment,
    ModalBalance,
    ModalClientDetail,
    AddSmsModalComponent,
    AddCallsAndSmsModal,
    CallsAndSmsTracking,
    NotificationAppModal,
    ReschedulePendingPaymentModal,
    ViewClientDetails,
    ModalSendStatement,
    ModalSendEmail,
    ModalHistoryMonthlyPayment,
    DerivationActionModal,
    DerivationTrackingModal,
    ResultSelect,
    ClientLabelSelect,
    ModalResultTracking,
    ModalLabelTracking,
    StatusAccount,
    ShowMoreText,
    PaymentSchedule,
    DashboardPayments,
    SmsClientHistory,
  },
  props: {
    statusSearch: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentClientAccountId: null,
      showSmsHistoryModal: false,
      showModalDashboardPayments: false,
      result: null,
      modalClientDetailC: false,
      receveiableType: null,
      dateMonth: "11",
      dateYear: "2022",

      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      filters: FiltersClientsData,
      fields: Fields,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      startPage: null,

      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },

      items: [],

      modalMonthlyPayment: false,

      type: null,

      subTotal: 0,
      total: 0,
      pending: 0,
      clientAccount: "",
      validateChargeOn: false,
      modalApproveSupervisorOn: false,
      itemId: "",
      stateProcessPayment: false,
      paymentInformation: {},
      client: {},
      stateBalance: false,
      typeApprove: "",
      nextPage: null,
      clientData: {},
      actionType: null,
      AddSmsModal: false,
      addCallsAndSmsModal: false,
      callsAndSmsTrackingModal: false,
      typeTracking: null,
      notificationAppModalOn: false,
      addSmsModal: false,
      reschedulePendingPaymentModal: false,
      totalAmount: 0,
      totalPending: 0,
      modalSendStatement: false,
      clientInformationT: {},
      name_clients_arr: [],
      modalSendEmail: false,
      modalHistoryPayment: false,
      selectedClientTwo: {
        id: null,
        program_name: null,
        client_name: null,
      },
      derivationActionModal: false,
      derivationData: null,
      derivationTrackingModal: false,
      results: [],
      labels: [],
      transaction_id: null,
      modalResultActive: false,
      modalLabelActive: false,
      other: null,
      u: null,
      client_account_id: null,
      showPaymentSchedule: false,
      statusNav: 8,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_EEUU_STATES: "CrmGlobalStore/G_EEUU_STATES",
      RELOAD_TRANSACTIONS_STATUS: "TransactionStatusStore/G_REFRESH",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
      G_CLIENTS_COUNTER_CURRENT: "FinancialStore/G_CLIENTS_COUNTER_CURRENT",
      G_CLIENTS_COUNTER_ONE_MONTH_LATE:
        "FinancialStore/G_CLIENTS_COUNTER_ONE_MONTH_LATE",
      G_CLIENTS_COUNTER_TWO_MONTH_LATE:
        "FinancialStore/G_CLIENTS_COUNTER_TWO_MONTH_LATE",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },

    visibleFields() {
      return this.fields;
    },

    paymentType: {
      get() {
        return this.filters[2].model;
      },
      set(value) {
        this.filters[2].visible = value;
      },
    },

    paymentDay: {
      get() {
        return this.filters[3].model;
      },
      set(value) {
        this.filters[3].visible = value;
      },
    },
  },
  watch: {
    paymentType(newVal) {
      if (newVal === 1) {
        this.paymentDay = true;
      } else {
        this.paymentDay = false;
      }
    },
    RELOAD_TRANSACTIONS_STATUS() {
      this.$refs.refClientsListActive.refresh();
    },
    statusNav(newStatusNav, oldStatusNav) {
      if (newStatusNav === oldStatusNav) return;
      this.$refs.refClientsListActive.refresh();
    },
  },
  async created() {
    for (let x = 2022; x <= moment().format("YYYY"); x++) {
      this.filters[1].options.push({ text: x, value: x });
    }
    this.addPreloader;
    await this.A_GET_EEUU_STATES();
    this.filters[16].options = this.G_EEUU_STATES;
    this.removePreloader;
  },
  mounted() {
    this.filters[1].model = parseInt(moment().format("YYYY"));
    this.filters[0].model = parseInt(moment().format("MM"));
  },

  methods: {
    async changeNav(value) {
      this.statusNav = value;
    },
    openPaymentSchedule(item) {
      this.paymentInformation = item;
      this.showPaymentSchedule = true;
    },
    // onAddCommentary(item) {
    //   this.$set(item, "count_commentaries", item.count_commentaries + 1);
    //   this.addCallsAndSmsModal = false;
    // },
    onAddSms(item) {
      this.$set(item, "count_sms", Number(item.count_sms) + 1);
      this.addSmsModal = false;
    },
    onAddNotification(item) {
      const payment = this.items.find(
        (payment) => payment.client_account_id == item.client_account_id
      );
      this.$set(payment, "count_not", Number(payment.count_not) + 1);
      this.notificationAppModalOn = false;
    },
    onSmsCallNotificationAdd(type, item) {
      if (type == 1) {
        this.$set(item, "count_call", Number(item.count_call) + 1);
      } else if (type == 2) {
        this.$set(item, "count_sms", Number(item.count_sms) + 1);
      } else if (type == 4) {
        this.$set(item, "count_not", Number(item.count_not) + 1);
      }
      this.addCallsAndSmsModal = false;
    },

    onSaveSelection(item, labelId, status) {
      this.$set(item, "client_label_id", labelId);
      this.$set(item, "status", status);
    },
    openResultTracking(transactionId) {
      this.transaction_id = transactionId;
      this.modalResultActive = true;
    },
    hasSameOptionSelected(value, item) {
      this.$set(item, "editingResult", value);
    },
    closeResultModal() {
      this.modalResultActive = false;
    },
    openLabelTracking(accountId) {
      this.client_account_id = accountId;
      this.modalLabelActive = true;
    },
    closeLabelModal() {
      this.modalLabelActive = false;
    },
    updateRow() {
      this.$set(this.items[this.derivationIndex], "is_derivated", 1);
      this.$set(this.items[this.derivationIndex], "is_in_sale_process", 1);
    },
    openDerivationTrackingModal(item) {
      this.derivationData = item;
      this.derivationData.id = item.lead_id;
      this.derivationTrackingModal = true;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    openDerivationActionModal(item) {
      this.derivationData = {
        id: item.lead_id,
        client_id: item.client_id,
        client_account_id: item.client_account_id,
        catcher_by: this.currentUser.user_id,
        program_origin_id:
          (this.$route.matched[0].meta.module >= 21 &&
            this.$route.matched[0].meta.module <= 26) ||
          this.$route.matched[0].meta.module === 6
            ? 3
            : null,
        module_id: this.$route.matched[0].meta.module,
        notes: null,
        lead_name: item.client_name,
      };
      this.derivationActionModal = true;
    },
    closeSendEmail() {
      this.modalSendEmail = false;
    },
    async modalSendEmailOpen(id, name) {
      this.name_clients_arr = [{ id, name }];
      this.modalSendEmail = true;
    },
    openModalHistoryPayment(item) {
      this.selectedClientTwo = item;
      this.modalHistoryPayment = true;
    },
    openModalSendStatement(client) {
      this.clientInformationT = client;
      this.modalSendStatement = true;
    },
    closePayModal() {
      // this.stateProcessPayment = false;
      this.$refs.refClientsListActive.refresh();
    },
    refreshTable() {
      (this.filters[1].model =
        this.filters[1].model || parseInt(moment().format("YYYY"))),
        (this.filters[0].model =
          this.filters[0].model || parseInt(moment().format("MM"))),
        this.$refs.refClientsListActive.refresh();
    },
    closeModalHistoryPayment() {
      this.modalHistoryPayment = false;
    },
    closeModal() {
      this.addCallsAndSmsModal = false;
      this.reschedulePendingPaymentModal = false;
      this.callsAndSmsTrackingModal = false;
      this.addSmsModal = false;
    },
    openAddSmsModal(item, type) {
      this.clientData = item;
      this.actionType = type;
      if (this.clientData.mobile !== null) {
        this.addSmsModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },

    openCallsAndSmsTrackingModal(item, type) {
      this.clientData = item;
      this.typeTracking = type;
      if (this.clientData.mobile !== null) {
        this.callsAndSmsTrackingModal = true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "This client don't have a mobile registered."
        );
      }
    },
    openBalance(item) {
      if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
        this.showModalDashboardPayments = true;
      } else {
        this.stateBalance = true;
      }
      this.paymentInformation = item;
    },

    processPayment(item) {
      this.paymentInformation = item;
      this.stateProcessPayment = true;
    },
    openModalMonthlyPayment(item) {
      this.clientAccount = item;
      this.modalMonthlyPayment = true;
    },
    closeModalMonthlyPayment() {
      this.modalMonthlyPayment = false;
    },
    async detailPaymentsProvider() {
      try {
        const params = {
          campo1: this.filterPrincipal.model,
          day: this.filters[3].model,
          month: this.filters[0].model || parseInt(moment().format("MM")),
          payment: this.filters[4].model,
          type: null,
          typepayment: this.filters[2].model,
          year: this.filters[1].model || parseInt(moment().format("YYYY")),
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          client_type: this.filters[5].model,
          last_payment_from: this.filters[6].model,
          last_payment_to: this.filters[7].model,
          initial_fee_from: this.filters[8].model,
          initial_fee_to: this.filters[9].model,
          pending_balance_from: this.filters[10].model,
          pending_balance_to: this.filters[11].model,
          monthly_payment_from: this.filters[12].model,
          monthly_payment_to: this.filters[13].model,
          contact_schedule_from: this.filters[14].model,
          contact_schedule_to: this.filters[15].model,
          client_state: this.filters[16].model,
          client_result_status: null,
          client_label_status: null,
          last_label_from: null,
          last_label_to: null,
          last_result_from: null,
          last_result_to: null,
          client_status: this.statusNav,
        };

        const data = await DashboardFinancial.getDetailOfClients(params);
        const payments = data.data.data;
        payments.forEach((payment) => {
          this.$set(payment, "editingResult", true);
        });
        if (data.status === 200) {
          this.items = payments;

          this.pending = this.total - this.subTotal;
          this.totalRows = data.data.total;
          this.startPage = data.data.from;
          this.toPage = data.data.to;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;

          this.totalAmount = data.data.data[0]?.amount_total ?? 0;
          this.totalPending = data.data.data[0]?.remaining ?? 0;
          return this.items || [];
        }
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    setResult(e, data) {
      data.client_result_id = e;
    },
    onOtherLabel(e, a, item) {
      if (e == null) {
        item.client_label_status = 3;
        item.status = 3;
        item.other_label = null;
      } else {
        item.other_label = e;
        item.client_label_status = 1;
        item.status = 1;
      }
    },
    ...mapActions({
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
    }),
    openSmsHistoryModal(clientAccountId) {
      this.currentClientAccountId = clientAccountId;
      this.showSmsHistoryModal = true;
    },
    closeSmsHistoryModal() {
      this.currentClientAccountId = null;
      this.showSmsHistoryModal = false;
    },
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}

.status_circle {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 15px !important;
}

.mp-pending {
  animation: growup 1.5s ease-in-out infinite;
  top: calc(50% - 10px);
}
@keyframes growup {
  0% {
    transform: scale(1);
    color: #ff9f43;
  }
  50% {
    transform: scale(0.99);
    color: #fc3a31;
  }
  100% {
    transform: scale(1);
    color: #ff9f43;
  }
}
</style>
