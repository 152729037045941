export default [
  {
    key: "client_name",
    label: "Client Name",
  },
  {
    key: "payment_type",
    label: "Payment Type",
  },
  // {
  //   key: 'charge',
  //   label: 'Charge',
  // },
  {
    key: "paid",
    label: "Paid",
  },

  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "balance",
    label: "Balance",
  },
  // {
  //   key: "zero_payment",
  //   label: "Zero",
  // },
  // {
  //   key: "count_call",
  //   label: "Counters",
  // },
  // {
  //   label: "SMS",
  //   key: "count_sms",
  // },
  // {
  //   label: "Noti",
  //   key: "count_not",
  // },
  // {
  //   key: "tracking",
  //   label: "Comm",
  // },
  // {
  //   key: "contact_schedule",
  //   label: "CT",
  // },
  // {
  //   label: "Result",
  //   key: "client_result",
  // },
  // {
  //   label: "Label",
  //   key: "client_label",
  // },
  {
    key: "last_payment",
    label: "LP",
  },
  {
    key: "client_state",
    label: "St",
  },
  {
    key: 'sms',
    label: 'SMS',
  },
  {
    key: 'enroll_date',
    label: 'Enroll Date',
  },
  // {
  //   key: 'fromm',
  //   label: 'From',
  // },
];
